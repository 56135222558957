import React, { useState, useEffect, useCallback } from "react";
import "./MonologueForm.css";
import ActorBreakdownForm from "../ActorBreakdownForm/ActorBreakdownForm";
import SceneSpecificForm from "../SceneSpecificForm/SceneSpecificForm";
import CharacterForm from "../CharacterForm/CharacterForm";

const MonologueForm = ({ onSubmit, isFormVisible, toggleFormVisibility }) => {
  const localStorageKey = "monologueFormData";
  const [isActorInfoVisible, setIsActorInfoVisible] = useState(true);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [formValues, setFormValues] = useState(() => {
    const savedFormData = localStorage.getItem(localStorageKey);

    if (savedFormData) {
      try {
        const parsedFormData = JSON.parse(savedFormData);
        return {
          ...parsedFormData,
          gender: "",
          sexuality: "",
          race: "",
          characterAgeRange: [0, 100],
          genre: "",
          sceneStyle: "",
          era: "",
          structure: "",
          timeStartEnd: [0, 8],
          themes: "",
          isExclusiveGenre: false,
          isExclusiveThemes: false,
        };
      } catch (error) {
        localStorage.removeItem(localStorageKey);
      }
    }
    return {
      age: "",
      gender: "",
      sexuality: "",
      race: "",
      languagesSpoken: "",
      accentAndDialect: "",
      formalTraining: "",
      workshops: "",
      experience: "",
      actingStyleAndTechnique: "",
      movementTechniques: "",
      characterAgeRange: [0, 100],
      genre: "",
      sceneStyle: "",
      era: "",
      structure: "",
      timeStartEnd: [0, 8],
      themes: "",
      isExclusiveGenre: false,
      isExclusiveThemes: false,
    };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkIfAllFieldsAreValid = useCallback(() => {
    const requiredFields = [
      "age",
      "languagesSpoken",
      "gender",
      "genre",
      "sceneStyle",
      "timeStartEnd",
      "characterAgeRange",
    ];

    return requiredFields.every((field) => {
      const value = formValues[field];
      if (Array.isArray(value)) {
        return (
          value.length > 0 &&
          value.every((v) => v !== null && v !== undefined && v !== "")
        );
      } else if (typeof value === "string") {
        return value.trim() !== "";
      } else {
        return value !== null && value !== undefined;
      }
    });
  });

  const saveToLocalStorage = (values) => {
    const dataToStore = {
      age: values.age,
      languagesSpoken: values.languagesSpoken,
      accentAndDialect: values.accentAndDialect,
      formalTraining: values.formalTraining,
      workshops: values.workshops,
      experience: values.experience,
      actingStyleAndTechnique: values.actingStyleAndTechnique,
      movementTechniques: values.movementTechniques,
    };
    localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
  };

  useEffect(() => {
    saveToLocalStorage(formValues);
  }, [formValues]);

  useEffect(() => {
    if (isErrorVisible && checkIfAllFieldsAreValid()) {
      setIsErrorVisible(false);
    }
  }, [formValues, isErrorVisible, checkIfAllFieldsAreValid]);

  const handleSelectChange = (value, data) => {
    const selectedValues = Array.isArray(value)
      ? value.map((item) => item.value)
      : value.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [data.name]: selectedValues,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!checkIfAllFieldsAreValid()) {
      setIsErrorVisible(true);
      return;
    }

    setIsErrorVisible(false);
    saveToLocalStorage(formValues);

    if (onSubmit) {
      onSubmit(formValues);
    }
  };

  const toggleActorInfoVisibility = () => {
    setIsActorInfoVisible(!isActorInfoVisible);
  };

  const handleClearForm = () => {
    setIsErrorVisible(false);

    setFormValues({
      age: "",
      gender: "",
      sexuality: "",
      race: "",
      languagesSpoken: "",
      accentAndDialect: "",
      formalTraining: "",
      workshops: "",
      experience: "",
      actingStyleAndTechnique: "",
      movementTechniques: "",
      characterAgeRange: [0, 100],
      genre: "",
      sceneStyle: "",
      timeStartEnd: [0, 8],
      themes: "",
      era: "",
      structure: "",
      isExclusiveGenre: false,
      isExclusiveThemes: false,
    });
    localStorage.removeItem(localStorageKey);
  };

  const handleRangeChange = (values, name) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: values,
    }));
  };

  const handleExclusiveChange = (exclusiveFieldName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [exclusiveFieldName]: !prevValues[exclusiveFieldName],
    }));
  };

  return (
    <div className="monologue-form">
      <div className="header-container" onClick={toggleFormVisibility}>
        <h2 className="header-text">Find your monologue</h2>
        <span
          className={`arrow-icon main-arrow ${isFormVisible ? "down" : "up"}`}
        ></span>
      </div>

      {isFormVisible ? (
        <div className="form-container">
          <hr />
          <form onKeyDown={handleKeyDown}>
            <ActorBreakdownForm
              isActorInfoVisible={isActorInfoVisible}
              toggleActorInfoVisibility={toggleActorInfoVisibility}
              formValues={formValues}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              isOnHomePage={true}
              isErrorVisible={isErrorVisible}
            />
            <CharacterForm
              formValues={formValues}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleRangeChange={handleRangeChange}
              isErrorVisible={isErrorVisible}
            />
            <SceneSpecificForm
              formValues={formValues}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleRangeChange={handleRangeChange}
              isErrorVisible={isErrorVisible}
              handleExclusiveChange={handleExclusiveChange}
            />
            {isErrorVisible && (
              <p className="errmsg" aria-live="assertive">
                Please fill in all required fields.
              </p>
            )}
            <button type="submit" onClick={handleSubmit}>
              Find your monologue
            </button>
            <button
              className="clear-form-button"
              type="button"
              onClick={handleClearForm}
            >
              Clear Form
            </button>{" "}
          </form>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MonologueForm;
