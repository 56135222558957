import React from "react";
import "./LoadingSpinner.css";
import { ClockLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <>
      <ClockLoader
        className="loading-spinner"
        color="#89cff0"
        size={125}
        speedMultiplier={0.5}
      />
      <div className="loading-text">
        This could be the start of something big
      </div>
    </>
  );
};

export default LoadingSpinner;
