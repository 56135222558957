import React, { useState } from "react";
import "./Home.css";
import MonologueForm from "../../components/forms/monologue/MonologueForm/MonologueForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useGetMonologueMutation } from "../../features/auth/authApiSlice";
import ServerError from "../../components/ServerError/ServerError";
import MonologueCard from "../../components/MonologueCard/MonologueCard";
import NoMonologuesFound from "../../components/NoMonologuesFound/NoMonologuesFound";

const Home = ({ isFormVisible, setIsFormVisible }) => {
  const [getMonologue] = useGetMonologueMutation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInternalError, setIsInternalError] = useState(false);
  const [isNoMonologuesFound, setIsNoMonologuesFound] = useState(false);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const currentToken = useSelector(selectCurrentToken);

  const fetchMonologue = async (formData) => {
    setLoading(true);
    setData([]);
    setIsInternalError(false);
    setIsNoMonologuesFound(false);

    try {
      const res = await getMonologue(formData).unwrap();
      const { content } = res;

      // content.forEach((el) => console.log(el.similarityDetails));

      if (content.length === 0) {
        setIsNoMonologuesFound(true);
      } else {
        setData(content);
      }

      // dispatch(setCredentials({ user, accessToken: currentToken }));
    } catch (err) {
      setIsInternalError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (formData) => {
    setIsFormVisible(false);
    fetchMonologue(formData);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
  };

  return (
    <div className="home-container">
      <h1 className="home-main-title">FIND A MONOLOGUE THAT BEST SUITS YOU</h1>
      <p className="home-subtitle">
        Tired of the same old monologues? In just a few short clicks Monologue
        Seeker's custom AI algorithm suggests personalized monologues that are
        tailored to your strengths, whether that's comedy, drama, or a specific
        character type. After filling out the form below, Monologue Seeker will
        find the best monologues that will help you find your spotlight.
      </p>
      <MonologueForm
        isFormVisible={isFormVisible}
        toggleFormVisibility={toggleFormVisibility}
        onSubmit={handleFormSubmit}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!isFormVisible && (
            <div className="home-answer-container">
              {data.map((item, index) => (
                <MonologueCard
                  key={item.id}
                  monologue={item.monologue}
                  index={index}
                  similarity={item.similarity * 100}
                />
              ))}
              {isInternalError && <ServerError />}
              {isNoMonologuesFound && <NoMonologuesFound />}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Home;
