import React from "react";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
// import LogIn from "./pages/LogIn/LogIn";
// import SignUp from "./pages/SignUp/SignUp";
// import Profile from "./pages/Profile/Profile";
// import History from "./pages/History/History";
// import RequireAuth from "./features/auth/RequireAuth";
import { Route, Routes, Navigate } from "react-router-dom";
import Footer from "./components/Footer/Footer";
// import ResetPassword from "./pages/ResetPassword/ResetPassword";
// import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
// import EmailVerification from "./pages/EmailVerification/EmailVerification";
// import Welcome from "./pages/Welcome/Welcome";
// import EmailNotVerified from "./pages/EmailNotVerified/EmailNotVerified";
// import RequestMoreTokens from "./pages/RequestMoreTokens/RequestMoreTokens";

function App() {
  const [isFormVisible, setIsFormVisible] = React.useState(true);

  return (
    <div className="page-container">
      <Navbar></Navbar>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              isFormVisible={isFormVisible}
              setIsFormVisible={setIsFormVisible}
            />
          }
        />
        {/* <Route path="/login" element={<LogIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verify-email/:token" element={<EmailVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<RequireAuth />}>
          <Route path="/email-not-verified" element={<EmailNotVerified />} />
          <Route path="/request-more-tokens" element={<RequestMoreTokens />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/history" element={<History />} />
        </Route>
        <Route path="/reset-password/:token" element={<ResetPassword />} /> */}
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
