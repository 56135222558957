/* eslint-disable import/no-anonymous-default-export */
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { apiSlice } from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";

// Define the persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Combine reducers
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignore these action types
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/REGISTER",
        ],
        // OR ignore paths in actions and state
        ignoredPaths: ["register"],
      },
    }).concat(apiSlice.middleware),
  devTools: false,
});

// Create a persistor instance
export const persistor = persistStore(store);

// Export the store and persistor
export default { store, persistor };
