import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="contact-container">
        Want to get in touch? Email{" "}
        <a className="email-link" href="mailto:info@monologueseeker.com">
          info@monologueseeker.com
        </a>
      </div>
      <div className="copyright-container">
        © {new Date().getFullYear()} Monologue Seeker. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
