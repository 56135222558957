import React from "react";
import "./Navbar.css";
// import CornerButton from "../CornerButton/CornerButton";
// import { useSelector } from "react-redux";
// import { selectCurrentUser } from "../../features/auth/authSlice";
// import { useLocation, useNavigate } from "react-router-dom";
// import UserMenu from "../UserMenu/UserMenu";

const Navbar = () => {
  // const [isCornerButtonVisible, setIsCornerButtonVisible] =
  //   React.useState(true);
  // const user = useSelector(selectCurrentUser);
  // const text = user ? `Welcome ${user.firstName}!` : "Log In";
  // const tokensRemaining = user ? user.numberOfRequests : 0;
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   const path = location.pathname;
  //   const hiddenPaths = [
  //     "/login",
  //     "/signup",
  //     "/forgot-password",
  //     "/email-not-verified",
  //   ];
  //   const isPathHidden =
  //     hiddenPaths.includes(path) ||
  //     path.startsWith("/reset-password/") ||
  //     path.startsWith("/verify-email/");

  //   setIsCornerButtonVisible(!isPathHidden);
  // }, [location.pathname]);

  // const navigateToHome = () => {
  //   navigate("/");
  // };

  // const navigateToLogin = () => {
  //   navigate("/login");
  // };

  return (
    <nav className="navbar">
      <div className="logoContainer">
        <h2 className="logo">Monologue Seeker</h2>
      </div>

      {/* {isCornerButtonVisible && (
        <div className="menu">
          {user ? (
            <UserMenu text={text} tokensRemaining={tokensRemaining} />
          ) : (
            <CornerButton onClick={navigateToLogin} text={text} />
          )}
        </div>
      )} */}
    </nav>
  );
};

export default Navbar;
