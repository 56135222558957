import React from "react";

const NoMonologuesFound = () => {
  return (
    <div>
      <h2>No monologues match your criteria.</h2>

      <h4> Please consider removing genre or themes restrictions.</h4>
    </div>
  );
};

export default NoMonologuesFound;
