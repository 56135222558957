export class FormConstants {
  static SCENE_STYLES = [
    { value: "classical", label: "Classical" },
    { value: "contemporary", label: "Contemporary" },
  ];

  static SEXUALITIES = [
    { value: "heterosexual", label: "Heterosexual" },
    { value: "homosexual", label: "Homosexual" },
    { value: "bisexual", label: "Bisexual" },
    { value: "any", label: "Any / Other" },
  ];

  static GENDERS = [
    { value: "female", label: "Female" },
    { value: "male", label: "Male" },
    { value: "any", label: "Any / Other" },
  ];

  static RACES = [
    { value: "mixed race", label: "Mixed race" },
    { value: "african", label: "African" },
    { value: "african american", label: "African american" },
    { value: "afro-european", label: "Afro-european" },
    { value: "caucasian", label: "Caucasian" },
    { value: "white", label: "White" },
    { value: "mediterranean", label: "Mediterranean" },
    { value: "greek", label: "Greek" },
    { value: "middle eastern", label: "Middle eastern" },
    { value: "asian", label: "Asian" },
    { value: "indian", label: "Indian" },
    { value: "hispanic", label: "Hispanic" },
    { value: "latin", label: "Latin" },
    { value: "jewish", label: "Jewish" },
    { value: "any", label: "Any / Other" },
  ];

  static LANGUAGES = [
    { value: "dutch", label: "Dutch" },
    { value: "english", label: "English" },
    { value: "french", label: "French" },
    { value: "german", label: "German" },
    { value: "greek", label: "Greek" },
    { value: "italian", label: "Italian" },
    { value: "latin", label: "Latin" },
    { value: "norwegian", label: "Norwegian" },
    { value: "russian", label: "Russian" },
    { value: "spanish", label: "Spanish" },
    { value: "swedish", label: "Swedish" },
    { value: "yiddish", label: "Yiddish" },
  ];

  static GENRES = [
    { value: "absurdist", label: "Absurdist" },
    { value: "biographical", label: "Biographical" },
    { value: "comedy", label: "Comedy" },
    { value: "drama", label: "Drama" },
    { value: "epic", label: "Epic" },
    { value: "experimental", label: "Experimental" },
    { value: "fantasy", label: "Fantasy" },
    { value: "farce", label: "Farce" },
    { value: "mystery", label: "Mystery" },
    { value: "romance", label: "Romance" },
    { value: "satire", label: "Satire" },
    { value: "scienceFiction", label: "Science Fiction" },
    { value: "socialCommentary", label: "Social Commentary" },
    { value: "surrealist", label: "Surrealist" },
    { value: "thriller", label: "Thriller" },
    { value: "tragedy", label: "Tragedy" },
  ];

  static THEMES = [
    { value: "acceptance", label: "Acceptance" },
    { value: "addiction", label: "Addiction" },
    { value: "advice", label: "Advice" },
    { value: "affairs", label: "Affairs" },
    { value: "aging", label: "Aging" },
    { value: "alcoholism", label: "Alcoholism" },
    { value: "alienation", label: "Alienation" },
    { value: "anger", label: "Anger" },
    { value: "anxiety", label: "Anxiety" },
    { value: "apologies", label: "Apologies" },
    { value: "art", label: "Art" },
    { value: "attention", label: "Attention" },
    { value: "bears", label: "Bears" },
    { value: "belief", label: "Belief" },
    { value: "betrayal", label: "Betrayal" },
    { value: "blind dates", label: "Blind dates" },
    { value: "breaking up", label: "Breaking up" },
    { value: "breakups", label: "Breakups" },
    { value: "broken promises", label: "Broken promises" },
    { value: "bullying", label: "Bullying" },
    { value: "capitalism", label: "Capitalism" },
    { value: "cats", label: "Cats" },
    { value: "change", label: "Change" },
    { value: "children", label: "Children" },
    { value: "climate change", label: "Climate change" },
    { value: "college", label: "College" },
    { value: "coming of age", label: "Coming of age" },
    { value: "community", label: "Community" },
    { value: "confidence", label: "Confidence" },
    { value: "confusion", label: "Confusion" },
    { value: "connection", label: "Connection" },
    { value: "connections", label: "Connections" },
    { value: "crime", label: "Crime" },
    { value: "criticism", label: "Criticism" },
    { value: "danger", label: "Danger" },
    { value: "dating", label: "Dating" },
    { value: "death", label: "Death" },
    { value: "delusion", label: "Delusion" },
    { value: "depression", label: "Depression" },
    { value: "desire", label: "Desire" },
    { value: "despair", label: "Despair" },
    { value: "desperation", label: "Desperation" },
    { value: "destiny", label: "Destiny" },
    { value: "destruction", label: "Destruction" },
    { value: "determination", label: "Determination" },
    { value: "disappointment", label: "Disappointment" },
    { value: "divorce", label: "Divorce" },
    { value: "drug use", label: "Drug use" },
    { value: "dying", label: "Dying" },
    { value: "eattherich", label: "Eattherich" },
    { value: "empowerment", label: "Empowerment" },
    { value: "emptiness", label: "Emptiness" },
    { value: "ennui", label: "Ennui" },
    { value: "entitlement", label: "Entitlement" },
    { value: "escape", label: "Escape" },
    { value: "evil", label: "Evil" },
    { value: "existence", label: "Existence" },
    { value: "existential crisis", label: "Existential crisis" },
    { value: "existentialism", label: "Existentialism" },
    { value: "fairy tale", label: "Fairy tale" },
    { value: "fame", label: "Fame" },
    { value: "family", label: "Family" },
    { value: "fear", label: "Fear" },
    { value: "feelings", label: "Feelings" },
    { value: "force", label: "Force" },
    { value: "foreign", label: "Foreign" },
    { value: "france", label: "France" },
    { value: "free will", label: "Free will" },
    { value: "friendship", label: "Friendship" },
    { value: "frustration", label: "Frustration" },
    { value: "fun", label: "Fun" },
    { value: "god", label: "God" },
    { value: "gratitude", label: "Gratitude" },
    { value: "grief", label: "Grief" },
    { value: "hate", label: "Hate" },
    { value: "heroism", label: "Heroism" },
    { value: "high school", label: "High school" },
    { value: "history", label: "History" },
    { value: "home", label: "Home" },
    { value: "hope", label: "Hope" },
    { value: "hopes", label: "Hopes" },
    { value: "humanity", label: "Humanity" },
    { value: "humor", label: "Humor" },
    { value: "hysterical", label: "Hysterical" },
    { value: "idealism", label: "Idealism" },
    { value: "immortality", label: "Immortality" },
    { value: "infidelity", label: "Infidelity" },
    { value: "inner torture", label: "Inner torture" },
    { value: "inspiration", label: "Inspiration" },
    { value: "introductions", label: "Introductions" },
    { value: "jealousy", label: "Jealousy" },
    { value: "jobs", label: "Jobs" },
    { value: "joy", label: "Joy" },
    { value: "justification", label: "Justification" },
    { value: "law", label: "Law" },
    { value: "leaving home", label: "Leaving home" },
    { value: "letters", label: "Letters" },
    { value: "life", label: "Life" },
    { value: "life changes", label: "Life changes" },
    { value: "literature", label: "Literature" },
    { value: "loneliness", label: "Loneliness" },
    { value: "lonliness", label: "Lonliness" },
    { value: "loss", label: "Loss" },
    { value: "love", label: "Love" },
    { value: "marriage", label: "Marriage" },
    { value: "memory", label: "Memory" },
    { value: "menace", label: "Menace" },
    { value: "mental illness", label: "Mental illness" },
    { value: "mercy", label: "Mercy" },
    { value: "mime", label: "Mime" },
    { value: "misfortune", label: "Misfortune" },
    { value: "missing persons", label: "Missing persons" },
    { value: "mistakes", label: "Mistakes" },
    { value: "money", label: "Money" },
    { value: "motherhood", label: "Motherhood" },
    { value: "mothers", label: "Mothers" },
    { value: "motivational", label: "Motivational" },
    { value: "moving", label: "Moving" },
    { value: "moving on", label: "Moving on" },
    { value: "murder", label: "Murder" },
    { value: "myth", label: "Myth" },
    { value: "numbness", label: "Numbness" },
    { value: "objectification", label: "Objectification" },
    { value: "pain", label: "Pain" },
    { value: "parenthood", label: "Parenthood" },
    { value: "parents", label: "Parents" },
    { value: "paris", label: "Paris" },
    { value: "peace", label: "Peace" },
    { value: "persistence", label: "Persistence" },
    { value: "phobia", label: "Phobia" },
    { value: "phobias", label: "Phobias" },
    { value: "pity", label: "Pity" },
    { value: "plays", label: "Plays" },
    { value: "poetry", label: "Poetry" },
    { value: "police", label: "Police" },
    { value: "poverty", label: "Poverty" },
    { value: "power", label: "Power" },
    { value: "protest", label: "Protest" },
    { value: "purpose", label: "Purpose" },
    { value: "reality", label: "Reality" },
    { value: "regret", label: "Regret" },
    { value: "regrets", label: "Regrets" },
    { value: "rejection", label: "Rejection" },
    { value: "relationships", label: "Relationships" },
    { value: "relatioonships", label: "Relatioonships" },
    { value: "resentment", label: "Resentment" },
    { value: "revenge", label: "Revenge" },
    { value: "risk", label: "Risk" },
    { value: "rivalry", label: "Rivalry" },
    { value: "romance", label: "Romance" },
    { value: "science", label: "Science" },
    { value: "science fiction", label: "Science fiction" },
    { value: "secrets", label: "Secrets" },
    { value: "self help", label: "Self help" },
    { value: "self-esteem", label: "Self-esteem" },
    { value: "self-knowledge", label: "Self-knowledge" },
    { value: "sex", label: "Sex" },
    { value: "siblings", label: "Siblings" },
    { value: "silence", label: "Silence" },
    { value: "simplicity", label: "Simplicity" },
    { value: "sisterhood", label: "Sisterhood" },
    { value: "snow", label: "Snow" },
    { value: "society", label: "Society" },
    { value: "sorrow", label: "Sorrow" },
    { value: "sports", label: "Sports" },
    { value: "suburbs", label: "Suburbs" },
    { value: "talent", label: "Talent" },
    { value: "teaching", label: "Teaching" },
    { value: "teenagers", label: "Teenagers" },
    { value: "theater", label: "Theater" },
    { value: "theft", label: "Theft" },
    { value: "therapy", label: "Therapy" },
    { value: "time", label: "Time" },
    { value: "tragedy", label: "Tragedy" },
    { value: "transformation", label: "Transformation" },
    { value: "truth", label: "Truth" },
    { value: "vices", label: "Vices" },
    { value: "violence", label: "Violence" },
    { value: "vitriol", label: "Vitriol" },
    { value: "work", label: "Work" },
    { value: "writing", label: "Writing" },
    { value: "youth", label: "Youth" },
  ];

  static AGE = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
    { value: 32, label: "32" },
    { value: 33, label: "33" },
    { value: 34, label: "34" },
    { value: 35, label: "35" },
    { value: 36, label: "36" },
    { value: 37, label: "37" },
    { value: 38, label: "38" },
    { value: 39, label: "39" },
    { value: 40, label: "40" },
    { value: 41, label: "41" },
    { value: 42, label: "42" },
    { value: 43, label: "43" },
    { value: 44, label: "44" },
    { value: 45, label: "45" },
    { value: 46, label: "46" },
    { value: 47, label: "47" },
    { value: 48, label: "48" },
    { value: 49, label: "49" },
    { value: 50, label: "50" },
    { value: 51, label: "51" },
    { value: 52, label: "52" },
    { value: 53, label: "53" },
    { value: 54, label: "54" },
    { value: 55, label: "55" },
    { value: 56, label: "56" },
    { value: 57, label: "57" },
    { value: 58, label: "58" },
    { value: 59, label: "59" },
    { value: 60, label: "60" },
    { value: 61, label: "61" },
    { value: 62, label: "62" },
    { value: 63, label: "63" },
    { value: 64, label: "64" },
    { value: 65, label: "65" },
    { value: 66, label: "66" },
    { value: 67, label: "67" },
    { value: 68, label: "68" },
    { value: 69, label: "69" },
    { value: 70, label: "70" },
    { value: 71, label: "71" },
    { value: 72, label: "72" },
    { value: 73, label: "73" },
    { value: 74, label: "74" },
    { value: 75, label: "75" },
    { value: 76, label: "76" },
    { value: 77, label: "77" },
    { value: 78, label: "78" },
    { value: 79, label: "79" },
    { value: 80, label: "80" },
    { value: 81, label: "81" },
    { value: 82, label: "82" },
    { value: 83, label: "83" },
    { value: 84, label: "84" },
    { value: 85, label: "85" },
    { value: 86, label: "86" },
    { value: 87, label: "87" },
    { value: 88, label: "88" },
    { value: 89, label: "89" },
    { value: 90, label: "90" },
    { value: 91, label: "91" },
    { value: 92, label: "92" },
    { value: 93, label: "93" },
    { value: 94, label: "94" },
    { value: 95, label: "95" },
    { value: 96, label: "96" },
    { value: 97, label: "97" },
    { value: 98, label: "98" },
    { value: 99, label: "99" },
    { value: 100, label: "100" },
    { value: 101, label: "101" },
    { value: 102, label: "102" },
    { value: 103, label: "103" },
    { value: 104, label: "104" },
    { value: 105, label: "105" },
    { value: 106, label: "106" },
    { value: 107, label: "107" },
    { value: 108, label: "108" },
    { value: 109, label: "109" },
    { value: 110, label: "110" },
    { value: 111, label: "111" },
    { value: 112, label: "112" },
    { value: 113, label: "113" },
    { value: 114, label: "114" },
    { value: 115, label: "115" },
    { value: 116, label: "116" },
    { value: 117, label: "117" },
    { value: 118, label: "118" },
    { value: 119, label: "119" },
    { value: 120, label: "120" },
  ];

  static STRUCTURES = [
    { value: "prose", label: "Prose" },
    { value: "verse", label: "Verse" },
    { value: "any", label: "Any" },
  ];

  static ERA = [
    { value: "elizabethan", label: "Elizabethan" },
    { value: "jacobean", label: "Jacobean" },
    { value: "any", label: "Any" },
  ];
}
