import React from "react";

const ServerError = () => {
  return (
    <div>
      <h2>
        Our servers are down for maintenance.
        <br />
        <br />
        Please try again later.
      </h2>
    </div>
  );
};

export default ServerError;
