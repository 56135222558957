import React from "react";
import Select from "react-select";
import { InputTypes } from "../../../../assets/input-types";
import { FormConstants } from "../../../../assets/form-constant-data";
import { Range } from "react-range";

const SceneSpecificForm = ({
  formValues,
  handleChange,
  handleSelectChange,
  handleRangeChange,
  isErrorVisible,
  handleExclusiveChange,
}) => {
  const shouldApplyErrorClass = (itemName, isRequired) =>
    isErrorVisible && isRequired && !formValues[itemName];

  const sceneSpecific = [
    {
      label: "Scene Style",
      name: "sceneStyle",
      value: formValues.sceneStyle,
      inputType: InputTypes.SingleSelect,
      options: FormConstants.SCENE_STYLES,
      isRequired: true,
    },
    {
      label: "Structure",
      name: "structure",
      value: formValues.structure,
      inputType: InputTypes.ConditionalSingleSelect,
      options: FormConstants.STRUCTURES,
    },
    {
      label: "Classical Era",
      name: "era",
      value: formValues.era,
      inputType: InputTypes.ConditionalSingleSelect,
      options: FormConstants.ERA,
    },
    {
      label: "Time Range",
      name: "timeStartEnd",
      value: formValues.timeStartEnd,
      inputType: InputTypes.Slider,
      options: { min: 0, max: 8 },
      isRequired: true,
      subText: "minutes",
    },
    {
      label: "Genre",
      name: "genre",
      value: formValues.genre,
      inputType: InputTypes.SingleSelect,
      options: FormConstants.GENRES,
      isRequired: true,
      canBeExclusive: true,
      exclusiveName: "isExclusiveGenre",
    },
    {
      label: "Themes",
      name: "themes",
      value: formValues.themes,
      inputType: InputTypes.MultiSelect,
      options: FormConstants.THEMES,
      canBeExclusive: true,
      exclusiveName: "isExclusiveThemes",
    },
  ];

  return (
    <>
      <h3 className="form-subtitle">Scene Specific</h3>
      <div className="form-element-pair">
        {sceneSpecific.map((item, index) => {
          if (
            item.inputType === InputTypes.ConditionalSingleSelect &&
            formValues.sceneStyle !== "classical"
          ) {
            return null;
          }

          return (
            <label key={index}>
              <div className="monologue-form-element-title">{item.label}:</div>
              {item.inputType === InputTypes.SingleSelect ||
              item.inputType === InputTypes.ConditionalSingleSelect ? (
                <Select
                  className={`input-component ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "select-error"
                      : ""
                  }`}
                  onChange={handleSelectChange}
                  options={item.options}
                  name={item.name}
                  value={
                    item.options.find(
                      (opt) => opt.value === formValues[item.name]
                    ) || ""
                  }
                  required={item.label.endsWith("*")}
                />
              ) : null}

              {item.inputType === InputTypes.SingleLineText ? (
                <input
                  className={`singe-text-input ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "error"
                      : ""
                  }`}
                  name={item.name}
                  value={formValues[item.name]}
                  onChange={handleChange}
                  required={item.label.endsWith("*")}
                />
              ) : null}

              {item.inputType === InputTypes.MultiSelect ? (
                <Select
                  className={`input-component ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "select-error"
                      : ""
                  }`}
                  onChange={handleSelectChange}
                  isMulti={true}
                  options={item.options}
                  name={item.name}
                  value={item.options.filter((opt) =>
                    formValues[item.name].includes(opt.value)
                  )}
                  required={item.label.endsWith("*")}
                />
              ) : null}

              {item.inputType === InputTypes.MultiLineText ? (
                <textarea
                  className={`input-multi-component ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "error"
                      : ""
                  }`}
                  name={item.name}
                  value={formValues[item.name]}
                  onChange={handleChange}
                  cols="30"
                  rows="3"
                  required={item.label.endsWith("*")}
                />
              ) : null}

              {item.inputType === InputTypes.Slider ? (
                <div className="slider-container">
                  <Range
                    step={1}
                    min={item.options.min}
                    max={item.options.max}
                    values={formValues[item.name]}
                    onChange={(values) => handleRangeChange(values, item.name)}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "3px",
                          width: "92%",
                          marginLeft: "2%",
                          marginTop: "20px",
                          backgroundColor: "#ccc",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "14px",
                          width: "14px",
                          borderRadius: "50%",
                          backgroundColor: "#999",
                        }}
                      />
                    )}
                  />
                  <div className="time-range-indicator">
                    {formValues[item.name][0]} - {formValues[item.name][1]}{" "}
                    {item.subText}
                  </div>
                </div>
              ) : null}

              {item.canBeExclusive && (
                <div className="exclusive-box-container">
                  <input
                    type="checkbox"
                    name={`${item.name}-exclusive`}
                    onChange={() => handleExclusiveChange(item.exclusiveName)}
                    checked={formValues[item.exclusiveName]}
                    className="exclusive-checkbox"
                  />
                  Show only monologues{" "}
                  {item.name === "themes" ? "with these" : "of this"}{" "}
                  {item.name}
                </div>
              )}

              {item.isRequired && (
                <small
                  className={`form-required-label ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "required-label-error"
                      : ""
                  }`}
                >
                  *Required
                </small>
              )}
            </label>
          );
        })}
      </div>
    </>
  );
};

export default SceneSpecificForm;
