import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    createAccount: builder.mutation({
      query: (accountDetails) => ({
        url: "/register",
        method: "POST",
        body: accountDetails,
      }),
    }),
    getMonologue: builder.mutation({
      query: (formData) => ({
        url: "/monologue",
        method: "POST",
        body: formData,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/forgot-password",
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/reset-password",
        method: "POST",
        body: body,
        responseHandler: "text",
      }),
    }),
    verifyEmail: builder.mutation({
      query: (token) => ({
        url: `/verify-email/${token}`,
        method: "GET",
      }),
    }),
    resendVerificationEmail: builder.mutation({
      query: () => ({
        url: "/send-verification-email",
        method: "POST",
        body: {},
      }),
    }),
    handleLogout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCreateAccountMutation,
  useGetMonologueMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
  useResendVerificationEmailMutation,
  useHandleLogoutMutation,
} = authApiSlice;
