import React from "react";
import Select from "react-select";
import { InputTypes } from "../../../../assets/input-types";
import { Range } from "react-range";
import { FormConstants } from "../../../../assets/form-constant-data";

const CharacterForm = ({
  formValues,
  handleChange,
  handleSelectChange,
  handleRangeChange,
  isErrorVisible,
}) => {
  const shouldApplyErrorClass = (itemName, isRequired) =>
    isErrorVisible && isRequired && !formValues[itemName];

  const sceneSpecific = [
    {
      label: "Character Gender",
      name: "gender",
      value: formValues.gender,
      inputType: InputTypes.SingleSelect,
      options: FormConstants.GENDERS,
      isRequired: true,
    },
    {
      label: "Character Age Range",
      name: "characterAgeRange",
      value: formValues.characterAgeRange,
      inputType: InputTypes.Slider,
      options: { min: 0, max: 100 },
      isRequired: true,
      subText: "years old",
    },
    {
      label: "Character Sexuality",
      name: "sexuality",
      value: formValues.sexuality,
      inputType: InputTypes.SingleSelect,
      options: FormConstants.SEXUALITIES,
    },
    {
      label: "Character Race",
      name: "race",
      value: formValues.race,
      inputType: InputTypes.MultiSelect,
      options: FormConstants.RACES,
    },
  ];

  return (
    <>
      <h3 className="form-subtitle">Character Specific</h3>
      <div className="form-element-pair">
        {sceneSpecific.map((item, index) => (
          <label key={index}>
            <div className="monologue-form-element-title">{item.label}:</div>
            {item.inputType === InputTypes.SingleSelect ? (
              <Select
                className={`input-component ${
                  shouldApplyErrorClass(item.name, item.isRequired)
                    ? "select-error"
                    : ""
                }`}
                onChange={handleSelectChange}
                options={item.options}
                name={item.name}
                value={
                  item.options.find(
                    (opt) => opt.value === formValues[item.name]
                  ) || ""
                }
                required={item.label.endsWith("*")}
              />
            ) : (
              <></>
            )}
            {item.inputType === InputTypes.SingleLineText ? (
              <input
                className={`singe-text-input ${
                  shouldApplyErrorClass(item.name, item.isRequired)
                    ? "error"
                    : ""
                }`}
                name={item.name}
                value={item.value}
                onChange={handleChange}
                required={item.label.endsWith("*")}
              ></input>
            ) : (
              <></>
            )}
            {item.inputType === InputTypes.MultiSelect ? (
              <Select
                className={`input-component ${
                  shouldApplyErrorClass(item.name, item.isRequired)
                    ? "select-error"
                    : ""
                }`}
                onChange={handleSelectChange}
                isMulti={true}
                options={item.options}
                name={item.name}
                value={item.options.filter((opt) =>
                  formValues[item.name].includes(opt.value)
                )}
                required={item.label.endsWith("*")}
              />
            ) : (
              <></>
            )}
            {item.inputType === InputTypes.MultiLineText ? (
              <textarea
                className={`input-multi-component ${
                  shouldApplyErrorClass(item.name, item.isRequired)
                    ? "error"
                    : ""
                }`}
                name={item.name}
                value={item.value}
                onChange={handleChange}
                cols="30"
                rows="3"
                required={item.label.endsWith("*")}
              ></textarea>
            ) : (
              <></>
            )}
            {item.inputType === InputTypes.Slider ? (
              <div className="slider-container">
                <Range
                  step={1}
                  min={item.options.min}
                  max={item.options.max}
                  values={item.value}
                  onChange={(values) => handleRangeChange(values, item.name)}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "3px",
                        width: "92%",
                        marginLeft: "2%",
                        marginTop: "20px",
                        backgroundColor: "#ccc",
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "14px",
                        width: "14px",
                        borderRadius: "50%",
                        backgroundColor: "#999",
                      }}
                    />
                  )}
                />
                <div className="time-range-indicator">
                  {item.value[0]} - {item.value[1]} {item.subText}
                </div>
              </div>
            ) : (
              <></>
            )}
            {item.isRequired && (
              <small
                className={`form-required-label ${
                  shouldApplyErrorClass(item.name, item.isRequired)
                    ? "required-label-error"
                    : ""
                }`}
              >
                *Required
              </small>
            )}
          </label>
        ))}
      </div>
    </>
  );
};

export default CharacterForm;
