import React from "react";
import Select from "react-select";
import { InputTypes } from "../../../../assets/input-types";
import { FormConstants } from "../../../../assets/form-constant-data";

const ActorBreakdownForm = ({
  formValues,
  isActorInfoVisible,
  toggleActorInfoVisibility,
  handleChange,
  handleSelectChange,
  isOnHomePage,
  isErrorVisible,
}) => {
  const shouldApplyErrorClass = (itemName, isRequired) =>
    isErrorVisible && isRequired && !formValues[itemName];

  const actorBreakdown = [
    {
      label: "Age",
      name: "age",
      value: formValues.age,
      inputType: InputTypes.SingleSelect,
      options: FormConstants.AGE,
      isRequired: true,
    },
    {
      label: "Languages Spoken",
      name: "languagesSpoken",
      value: formValues.languagesSpoken,
      inputType: InputTypes.MultiSelect,
      options: FormConstants.LANGUAGES,
      isRequired: true,
    },
    {
      label: "Accents and Dialects",
      name: "accentAndDialect",
      value: formValues.accentAndDialect,
      inputType: InputTypes.MultiLineText,
    },
    {
      label: "Formal Training",
      name: "formalTraining",
      value: formValues.formalTraining,
      inputType: InputTypes.MultiLineText,
    },
    {
      label: "Workshops",
      name: "workshops",
      value: formValues.workshops,
      inputType: InputTypes.MultiLineText,
    },
    {
      label: "Experience",
      name: "experience",
      value: formValues.experience,
      inputType: InputTypes.MultiLineText,
    },
    {
      label: "Acting Styles and Techniques",
      name: "actingStyleAndTechnique",
      value: formValues.actingStyleAndTechnique,
      inputType: InputTypes.MultiLineText,
    },
    {
      label: "Movement Techniques",
      name: "movementTechniques",
      value: formValues.movementTechniques,
      inputType: InputTypes.MultiLineText,
    },
  ];

  return (
    <>
      <div className="header-container" onClick={toggleActorInfoVisibility}>
        <h3 className="header-text">Actor Breakdown</h3>
        <span
          className={`arrow-icon actor-arrow ${
            isActorInfoVisible ? "down" : "up"
          }`}
        ></span>
      </div>
      {isActorInfoVisible ? (
        <div className="form-element-pair">
          {actorBreakdown.map((item, index) => (
            <label key={index}>
              <div className="monologue-form-element-title">{item.label}:</div>
              {item.inputType === InputTypes.SingleSelect ? (
                <Select
                  className={`input-component ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "select-error"
                      : ""
                  }`}
                  onChange={handleSelectChange}
                  options={item.options}
                  name={item.name}
                  value={
                    item.options.find(
                      (opt) => opt.value === formValues[item.name]
                    ) || ""
                  }
                  required={item.label.endsWith("*")}
                />
              ) : (
                <></>
              )}
              {item.inputType === InputTypes.SingleLineText ? (
                <input
                  className={`singe-text-input ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "error"
                      : ""
                  }`}
                  name={item.name}
                  value={item.value}
                  onChange={handleChange}
                  required={item.label.endsWith("*")}
                ></input>
              ) : (
                <></>
              )}
              {item.inputType === InputTypes.MultiSelect ? (
                <Select
                  className={`input-component ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "select-error"
                      : ""
                  }`}
                  onChange={handleSelectChange}
                  isMulti={true}
                  options={item.options}
                  name={item.name}
                  value={item.options.filter((opt) =>
                    formValues[item.name].includes(opt.value)
                  )}
                  required={item.label.endsWith("*")}
                />
              ) : (
                <></>
              )}
              {item.inputType === InputTypes.MultiLineText ? (
                <textarea
                  className={`input-multi-component ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "error"
                      : ""
                  }`}
                  name={item.name}
                  value={item.value}
                  onChange={handleChange}
                  cols="30"
                  rows="3"
                  required={item.label.endsWith("*")}
                ></textarea>
              ) : (
                <></>
              )}
              {item.isRequired && (
                <small
                  className={`form-required-label ${
                    shouldApplyErrorClass(item.name, item.isRequired)
                      ? "required-label-error"
                      : ""
                  }`}
                >
                  *Required
                </small>
              )}
            </label>
          ))}
        </div>
      ) : (
        <div>{isOnHomePage && <hr />}</div>
      )}
    </>
  );
};

export default ActorBreakdownForm;
