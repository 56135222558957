import React, { useState } from "react";
import "./MonologueCard.css";

const MonologueCard = ({ monologue, index, similarity }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const getSimilarityStyle = (similarity) => {
    if (similarity >= 80) return { color: "green" };
    if (similarity >= 60) return { color: "#FAD02C" };
    if (similarity >= 40) return { color: "orange" };
    return { color: "red" };
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="monologue-card">
      <div className="monologue-header" onClick={toggleDropdown}>
        <div className="monologue-title">
          {index + 1}. {monologue.characterName} in "{monologue.playTitle}"
        </div>
        <div
          className="monologue-similarity"
          style={getSimilarityStyle(similarity)}
        >
          {similarity.toFixed(2)}%
        </div>
      </div>
      <div className="view-more" onClick={toggleDropdown}>
        {isOpen ? "View less ..." : "View more ..."}
      </div>
      {isOpen && (
        <div className="monologue-details">
          <div>Title: {monologue.playTitle}</div>
          <div>Author: {monologue.playAuthor}</div>
          <div>Character: {monologue.characterName}</div>
          <div>
            Character Gender: {capitalizeFirstLetter(monologue.characterGender)}
          </div>
          <div>
            Character Age Range: {monologue.characterAge.join(" - ")} years old
          </div>
          <div>Scene Type: {capitalizeFirstLetter(monologue.sceneType)}</div>
          <div>
            Genres:{" "}
            {monologue.genre
              .map((genre) => {
                return capitalizeFirstLetter(genre);
              })
              .join(", ")}
          </div>
          <div>
            Themes:{" "}
            {monologue.themes
              .map((theme) => {
                return capitalizeFirstLetter(theme);
              })
              .join(", ")}
          </div>
          <div>
            Time Range: {monologue.monologueTimeRange.join(" - ")} minutes
          </div>

          {/* <div>Scene Synopsis: {monologue.sceneSynopsis}</div>
          <div> Play Synopsis: {monologue.playSynopsis}</div> */}
          <div>
            Monologue Text:
            <div className="monologue-text-container">
              {monologue.monologue.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>

          <div>Suggested Workshops: {monologue.actorBreakdown.workshops}</div>
          <div>
            Suggested Acting Techniques:{" "}
            {monologue.actorBreakdown.actingStyleAndTechniques}
          </div>
          <div>
            Suggested Movements Techniques:{" "}
            {monologue.actorBreakdown.movementTechniques}
          </div>
        </div>
      )}
    </div>
  );
};

export default MonologueCard;
